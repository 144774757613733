import styled from "styled-components";
import Button from "../../components/Button";
import { DotWave } from "@uiball/loaders";

const Container = styled.div`
  padding: 10px 10px 10px 10px;
`;
const Loading = styled.div`
  display: flex;
  justify-content: center;
  margin-bottom: 20px;
`;
const EmailSvg = styled.svg`
  margin: 0 auto;
  display: block;
  //   margin-bottom: 19px;
`;

const Heading = styled.h1`
  font-size: 1.88rem;
  font-weight: 300;
  color: #111111;
  line-height: 2.88rem;
  letter-spacing: -1px;
  text-align: center;
  //   margin-top: 66px;
  //   margin-bottom: 24px;
`;

const Description = styled.p`
  font-size: 1rem;
  font-weight: 300;
  color: #333333;
  text-align: center;
  line-height: 1.75rem;
  letter-spacing: -0.5px;
  //   margin-bottom: 61px;
`;
const SendSuccessModal = ({ friends, isSending, okHandler }) => {
  return (
    <Container>
      {isSending ? (
        <Loading>
          <DotWave size={47} speed={1} color="#FF812C" />
        </Loading>
      ) : (
        <EmailSvg
          width="42"
          height="36"
          viewBox="0 0 42 36"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M27.65 36L19.6 27.95L21.75 25.8L27.65 31.7L39.85 19.5L42 21.65L27.65 36ZM19.9 13.65L36.6 3H3.2L19.9 13.65ZM19.9 16.65L3 5.8V28.4H15.8L18.8 31.4H3C2.2 31.4 1.5 31.1 0.9 30.5C0.3 29.9 0 29.2 0 28.4V3C0 2.2 0.3 1.5 0.9 0.9C1.5 0.3 2.2 0 3 0H36.85C37.65 0 38.35 0.3 38.95 0.9C39.55 1.5 39.85 2.2 39.85 3V15.2L36.85 18.2V5.8L19.9 16.65Z"
            fill="#FF812C"
          />
        </EmailSvg>
      )}

      <Heading
        style={{
          fontSize: "1.44rem",
          fontWeight: 600,
          marginBottom: "15px",
        }}
      >
        {isSending ? "메일을 보내고 있어요" : "메일이 발송되었어요"}
      </Heading>
      {isSending ? (
        <Description style={{ fontSize: "1rem", marginBottom: "32px" }}>
          설문 요청 메일을 발송 중입니다.
          <br />
          잠시만 기다려 주세요.
        </Description>
      ) : (
        <Description style={{ fontSize: "1rem", marginBottom: "32px" }}>
          입력하신 {friends.length}명에게 성공적으로
          <br />
          설문 요청 메일을 발송하였습니다!
        </Description>
      )}

      <Button
        type="confirm"
        onClick={okHandler}
        text={"눌러서 확인하기"}
        disabled={isSending}
      />
    </Container>
  );
};

export default SendSuccessModal;
