import styled from "styled-components";

const H1 = styled.h1`
  font-size: 2rem;
  font-weight: 300;
  color: #111111;
  line-height: 2.81rem;
  letter-spacing: -1px;
  text-align: center;
  margin-top: 70px;
`;

const Header = ({ children, style }) => {
  return <H1 style={style}>{children}</H1>;
};

export default Header;
