import { Outlet } from "react-router-dom";
import styled from "styled-components";

const Container = styled.div`
  width: 100%;
  height: 100vh;
  position: relative;
  padding: 57px 20px;
`;

const Logo = styled.img`
  position: absolute;
  top: 17px;
  right: 21px;

  width: 26px;
  height: 25px;
`;

const Footer = styled.p`
  position: absolute;
  left: 0;
  right: 0;
  bottom: 27px;
  text-align: center;
  font-size: 13px;
  color: #bbbbbb;
  font-weight: 400;
`;

const MLayout = ({ style }) => {
  return (
    <Container style={style}>
      <Logo
        src={`${process.env.PUBLIC_URL}/assets/logo/mobile_logo_gray.png`}
      />
      <Outlet />
      <Footer>베러댄피드백™</Footer>
    </Container>
  );
};

export default MLayout;
