import styled from "styled-components";
import Button from "../../components/Button";
import ErrorText from "../../components/ErrorText";

const Container = styled.div``;

const Heading = styled.h1`
  font-size: 1.88rem;
  font-weight: 300;
  color: #111111;
  line-height: 2.44rem;
  letter-spacing: -1px;
  text-align: center;
  margin-top: 10px;
  margin-bottom: 0.94rem;
`;

const Highlight = styled.span`
  color: ${({ theme }) => theme.orange};
  font-weight: 700;
  font-size: 1.88rem;
`;

const Description = styled.p`
  font-size: 1rem;
  font-weight: 300;
  color: #333333;
  text-align: center;
  line-height: 1.63rem;
  letter-spacing: -0.5px;
  margin-bottom: 40px;
`;

const Warning = styled.span`
  color: ${({ theme }) => theme.orange};
  font-weight: 600;
`;

const ButtonContainer = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
`;

const List = styled.ul`
  margin-bottom: 2.5rem;
  overflow-y: scroll;
  height: 330px;
  //   border: 1px solid red;
`;

const ListRow = styled.li`
  display: flex;
  flex-direction: column;
  justify-contents: center;
  border-bottom: 1px solid #d8d8d8;
  width: 100%;
  padding: 15px 10px;
  &:last-child {
    border: none;
  }
`;
const ListColumn = styled.div`
  color: #333;
  font-weight: 400;
  font-size: 1.13rem;
  text-align: center;
  &:first-child {
    color: ${({ theme }) => theme.orange};
    width: 20px;
    margin-right: 30px;
    // flex: 10%;
  }
  &:nth-child(2) {
    flex: auto;
    // border: 1px solid black;
    text-align: start;
  }
  //   border: 1px solid black;
`;

const FriendRow = ({ friend, isDuplicate, index }) => {
  console.log(isDuplicate);
  return (
    <ListRow>
      <div style={{ display: "flex" }}>
        <ListColumn>{index}</ListColumn>
        {/* <ListColumn>{friend.name}</ListColumn> */}
        <ListColumn>{friend.email}</ListColumn>
      </div>
      {isDuplicate && (
        <ErrorText
          msg={`최근 3번 이상 요청 받은 구성원입니다. 가능하다면 다른 구성원으로
        입력해주세요.`}
          style={{ marginTop: "0.5rem", fontSize: "13px" }}
        />
      )}
    </ListRow>
  );
};

const FriendListModal = ({
  friends,
  duplicatedFriends,
  okHandler,
  closeHandler,
}) => {
  const isDuplicate = (email) => {
    return duplicatedFriends.includes(email);
  };
  return (
    <Container>
      <Heading>
        아래와 같이 <Highlight>{friends.length}</Highlight>명에게 <br />
        설문을 요청하시겠어요?
      </Heading>
      <Description>
        이름과 이메일 주소를 한번 더 확인하시고,
        <br /> 설문 발송하기 버튼을 눌러주세요.
        <br />
        <Warning>(이후 수정 및 재발송 불가)</Warning>
      </Description>
      <List>
        {friends.map((friend, index) => (
          <FriendRow
            key={index}
            friend={friend}
            isDuplicate={isDuplicate(friend.email)}
            index={index + 1}
          />
        ))}
      </List>

      <ButtonContainer>
        <Button
          text={"수정하기"}
          type="cancel"
          onClick={closeHandler}
          width={"214px"}
        />
        <Button text={"설문 발송하기"} onClick={okHandler} width={"214px"} />
      </ButtonContainer>
    </Container>
  );
};

export default FriendListModal;
