import styled from "styled-components";

const Container = styled.div`
  border: 1px solid ${({ theme }) => theme.orange};
  width: 50px;
  height: 50px;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0 auto;
  margin-top: 30px;
  margin-bottom: 30px;
`;

const AddSvg = () => (
  <svg
    width="18"
    height="18"
    viewBox="0 0 18 18"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M8.32209 17.9262V0.316406H9.65841V17.9262H8.32209ZM0.200195 9.78947V8.45315H17.7952V9.78947H0.200195Z"
      fill="#FF812C"
    />
  </svg>
);

const AddBtn = ({ onClick }) => {
  return (
    <Container onClick={onClick}>
      <AddSvg />
    </Container>
  );
};

export default AddBtn;
