import { useState } from "react";
import styled from "styled-components";
import ClearBtn from "./ClearBtn";
import ErrorText from "./ErrorText";

const Container = styled.div`
  position: relative;
  width: 100%;
`;

const SInput = styled.input`
  height: 40px;
  border: 1px solid ${({ borderColor }) => borderColor};
  border-radius: 0.63rem;
  padding: 0.94rem 1.19rem;
  font-size: 0.94rem;
  font-weight: 400;
  line-height: 1.38rem;
  &: placeholder {
    color: #bbbbbb;
  }
  width: 100%;
`;

const Input = ({
  type,
  placeholder,
  value,
  setValue,
  onChange,
  onBlur,
  onKeyDown,
  setError,
  error,
  style,
}) => {
  const [borderColor, setBorderColor] = useState("#d8d8d8");

  const handleInputFocus = () => {
    setBorderColor("#111111");
    // console.log(setError);
    if (setError) {
      setError("");
    }
  };
  const handleInputBlur = (e, handler) => {
    if (handler) {
      handler(e);
    }

    if (error?.length > 0) {
      setBorderColor("#D60606");
    } else {
      setBorderColor("#D8D8D8");
    }
  };
  return (
    <Container>
      <SInput
        type={type}
        placeholder={placeholder}
        value={value}
        onChange={onChange}
        borderColor={borderColor}
        onFocus={handleInputFocus}
        onBlur={(e) => handleInputBlur(e, onBlur)}
        onKeyDown={onKeyDown}
        style={style}
      />
      {value?.length > 0 && (
        <ClearBtn
          style={{ top: "11px", right: "16px" }}
          onClick={() => {
            setValue("");
            setError("");
          }}
        />
      )}
      {/* {error && (
        <ErrorText
          msg={error}
          style={{ marginTop: "0.63rem", marginBottom: "16px" }}
        />
      )} */}
    </Container>
  );
};

export default Input;
