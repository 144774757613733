import styled from "styled-components";

const Div = styled.div`
  width: 100%;
  height: ${({ height }) => height}px;
`;

const Spacer = ({ height }) => {
  return <Div height={height} />;
};

export default Spacer;
