import { useRef } from "react";
import { useState } from "react";
import { useLocation } from "react-router-dom";
import styled from "styled-components";
import Button from "../../components/Button";
import Description from "../../components/Description";
import FormBox from "../../components/FormBox";
import Header from "../../components/Header";
import Modal from "../../components/Modal";
import ModalBackground from "../../components/ModalBackground";
import MessageCopyModal from "./MessageCopyModal";

const Section = styled.section`
  width: 100%;
  display: flex;
  justify-content: center;
  margin: 40px 0;
  position: relative;
  //   border: 1px solid black;
`;
const Image = styled.img`
  width: 19rem;
  height: auto;
`;
const Message = styled.p`
  position: absolute;
  font-size: 0.69rem;
  font-weight: 400;
  line-height: 0.94rem;
  width: 12.56rem;
  top: 50%;
  transform: translateY(-50%);
  //   border: 1px solid black;
  word-break: keep-all;
  word-wrap: break-word;
  white-space: pre-wrap;
`;

const FormMessage = () => {
  const [showModal, setShowModal] = useState(false);
  const messageRef = useRef();
  const {
    state: { projectName, link, date },
  } = useLocation();

  console.log(link, date);
  const handleClick = async () => {
    // copy to clipboard
    const msg = `안녕하세요 :)
    
저는 파트장이라는 새로운 역할을 인식하고, 다양한 변화에 잘 대응하기 위한 필요역량을 준비하기 위해 ‘${projectName}’에 참여합니다.
    
과정의 일환으로 주변 동료에게 피드백을 받아 성장자원을 탐색하는 시간이 있어 이렇게 설문 작성을 요청드립니다.
    
아래 전달드리는 설문조사 링크에 접속하여 간단한 설문을 부탁드리겠습니다. 

- 설문 링크 : ${link}
- 설문 내용 : 대상자의 강점, 가치, 고마웠던 순간 및 기대하는 역할 등
  ※ 총  8문항, 약 4분 소요
  ※ ~${date} 18:00까지
              
바쁘시겠지만, 잠시 시간을 내어 설문을 진행해주시길 부탁드립니다.
              
당신의 피드백은 동료의 긍정적인 변화의 시작점이 됩니다.
              
감사합니다 :)`;
    try {
      await navigator.clipboard.writeText(msg);
      setShowModal(true);
    } catch (e) {
      alert("복사에 실패하였습니다");
    }
    // show modal
  };

  return (
    <FormBox width={"600px"} height={"1040px"}>
      <Header
        style={{
          fontSize: "1.88rem",
          fontWeight: 600,
          marginBottom: "0.88rem",
        }}
      >
        설문 요청 예시 문구
      </Header>
      <Description>
        ‘복사하기’ 버튼을 누르면 메시지 내 문구가 전체 복사됩니다 :)
      </Description>
      <Section>
        <Image src={`${process.env.PUBLIC_URL}/assets/chat_layout.png`} />
        <Message ref={messageRef}>
          안녕하세요 :)
          <br />
          <br />
          저는 파트장이라는 새로운 역할을 인식하고, 다양한 변화에 잘 대응하기
          위한 필요역량을 준비하기 위해 ‘{projectName}’에 참여합니다.
          <br />
          <br />
          과정의 일환으로 주변 동료에게 피드백을 받아 성장자원을 탐색하는 시간이
          있어 이렇게 설문 작성을 요청드립니다.
          <br />
          <br />
          아래 전달드리는 설문조사 링크에 접속하여 간단한 설문을
          부탁드리겠습니다.
          <br />
          <br />
          <li>
            <span style={{ fontWeight: "bold" }}>설문 링크 : </span>
            <span style={{ color: "#FF812C" }}>{link}</span>
          </li>
          <li>
            설문 내용 : 대상자의 강점, 가치, 고마웠던 순간 및 기대하는 역할 등
          </li>
          <ol>
            <li style={{ textIndent: 10 }}>※ 총  8문항, 약 4분 소요</li>
            <li style={{ textIndent: 10 }}>※ ~{date} 18:00까지</li>
          </ol>
          <br />
          바쁘시겠지만, 잠시 시간을 내어 설문을 진행해주시길 부탁드립니다.
          <br />
          <br />
          당신의 피드백은 동료의 긍정적인 변화의 시작점이 됩니다.
          <br />
          <br />
          감사합니다 :)
        </Message>
      </Section>
      <Button text={"복사하기"} onClick={handleClick}></Button>
      {showModal && (
        <ModalBackground>
          <Modal width={"440px"} height={"388px"}>
            <MessageCopyModal okHandler={() => setShowModal(false)} />
          </Modal>
        </ModalBackground>
      )}
    </FormBox>
  );
};

export default FormMessage;
