import { createGlobalStyle } from "styled-components";
import reset from "styled-reset";

const GlobalStyle = createGlobalStyle`
    ${reset};
    :root {
        --vh: 100%;
    }
    * {
        box-sizing: border-box;
    }
    body{
        // padding: env(safe-area-inset-top, 20px) env(safe-area-inset-right, 20px) env(
        //     safe-area-inset-bottom,
        //     20px
        //   ) env(safe-area-inset-left, 20px);
        margin: 0;
        padding: 0;
        font-family: 'Pretendard', sans-serif;
        background:#F8F8F8;
    };
    button{
        display: flex;
        cursor: pointer;
        outline: none;
        border-radius: 3px;
    };
    input{
        display: flex;
        outline: none;
    }
    input, textarea, select { font-family:inherit; }
`;

export default GlobalStyle;
