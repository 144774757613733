import styled from "styled-components";

const Container = styled.div`
  width: ${({ width }) => width || "43.75rem"};
  height: ${({ height }) => height || "46.88rem"};
  border-radius: 3.13rem;
  background: white;
  padding: 0 58px;
  position: relative;
  //   border: 1px solid black;
  display: flex;
  flex-direction: column;
`;

const Wrapper = styled.div`
  width: 100%;
  height: 100%;

  position: relative;
  border: 1px solid black;
`;

const Logo = styled.img`
  width: 45px;
  height: 45px;
  position: absolute;
  right: 2.38rem;
  top: 2.38rem;
`;

const Footer = styled.footer`
  position: absolute;
  left: 0;
  bottom: 40px;
  padding: 0 58px;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
`;
const Divider = styled.div`
  height: 1px;
  width: 100%;
  background: #d8d8d8;
  margin-bottom: 1.13rem;
`;

const FooterText = styled.span`
  font-size: 0.81rem;
  font-weight: 400;
  line-height: 1.19rem;
  color: ${({ theme }) => theme.placeholderColor};
`;

const FormBox = ({ children, logo = true, width, height, style }) => {
  return (
    <Container width={width} height={height} style={style}>
      {logo && (
        <Logo src={`${process.env.PUBLIC_URL}/assets/logo/logo_gray.png`} />
      )}
      {/* <Wrapper /> */}
      {children}
      <Footer>
        <Divider />
        <FooterText>베러댄피드백™</FooterText>
      </Footer>
    </Container>
  );
};

export default FormBox;
