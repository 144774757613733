import styled from "styled-components";

const Container = styled.div`
  width: 100%;
  height: 100%;
  background: white;
  border-radius: 1.56rem;
  padding: 45px 20px;
`;

const MFormBox = ({ children, style }) => {
  return <Container style={style}>{children}</Container>;
};

export default MFormBox;
