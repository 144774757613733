import axios from "axios";
import { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import styled from "styled-components";
import Button from "../../components/Button";
import FormBox from "../../components/FormBox";
import FriendInput from "../../components/FriendInput";
import Modal from "../../components/Modal";
import ModalBackground from "../../components/ModalBackground";
import { validateEmail } from "../../utils";
import FriendListModal from "./FriendListModal";
import SendSuccessModal from "./SendSuccessModal";

const Heading = styled.h1`
  font-size: 1.88rem;
  font-weight: 300;
  color: #111111;
  line-height: 2.88rem;
  letter-spacing: -1px;
  text-align: center;
  margin-top: 66px;
  margin-bottom: 15px;
`;

const Description = styled.p`
  font-size: 1rem;
  font-weight: 400;
  color: #333333;
  text-align: center;
  line-height: 1.75rem;
  letter-spacing: -0.5px;
  margin-bottom: 30px;
`;

const List = styled.div`
  //   overflow-y: scroll;
  //   border: 1px solid black;
  //   height: 370px;
`;

const AddButton = styled.button`
  width: 50px;
  height: 50px;
  border-radius: 50%;
  border: 1px solid ${({ theme }) => theme.orange};
  background: white;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 20px;
  color: ${({ theme }) => theme.orange};
  margin: 0 auto;
  margin-top: 26px;
  margin-bottom: 26px;
`;

const FriendList = () => {
  const [count, setCount] = useState(5);
  const [showModal, setShowModal] = useState(false);
  const [showSendSuccessModal, setShowSendSuccessModal] = useState(false);
  const [friends, setFriends] = useState([]);
  const [finalFriends, setFinalFriends] = useState([]);
  const [duplicatedFriends, setDuplicatedFriends] = useState([]);
  const navigate = useNavigate();
  const { state } = useLocation();
  //   const [errorNameAt, setErrorNameAt] = useState();
  const [errorAt, setErrorAt] = useState();
  const [isSending, setIsSending] = useState(false);

  const validateFriends = () => {
    const filteredFriends = friends.filter((f) => f.email);
    console.log(filteredFriends);
    if (filteredFriends.length === 0) {
      return false;
    }

    for (let i = 0; i < filteredFriends.length; i++) {
      if (!filteredFriends[i]) {
        continue;
      }
      //   if (!friends[i].name) {
      //     setErrorNameAt({ index: i, msg: "이름을 입력하세요." });
      //     return false;
      //   }
      if (validateEmail(filteredFriends[i].email)) {
        continue;
      } else {
        setErrorAt({ index: i, msg: "이메일을 입력하세요" });
        // console.log(i);
        return false;
      }
    }
    setFinalFriends(filteredFriends);

    return true;
  };

  const handleAddClick = () => {
    setCount((cur) => cur + 1);
  };

  const handleSubmitClick = async () => {
    const filteredFriends = friends.filter((f) => f.email);

    // localStorage.setItem("friends", JSON.stringify(friends));
    if (validateFriends(filteredFriends)) {
      const response = await axios.get(
        `${process.env.REACT_APP_API_SERVER_DOMAIN}/betterthan/friends`,
        {
          params: {
            friends: filteredFriends,
            pid: state.pid,
            email: state.email,
          },
        }
      );
      setDuplicatedFriends(response.data.duplicatedFriends);
      setShowModal(true);
    }
  };

  const handleCloseModal = () => {
    setShowModal(false);
  };

  const handleConfirmClick = async () => {
    setIsSending(true);
    setShowModal(false);
    setShowSendSuccessModal(true);
    try {
      const respone = await axios.post(
        `${process.env.REACT_APP_API_SERVER_DOMAIN}/betterthan/friends`,
        {
          friends: finalFriends,
          pid: state.pid,
          email: state.email,
        }
      );
    } catch (err) {
    } finally {
      setIsSending(false);
    }
  };

  const sendSuccessConfirm = () => {
    navigate("/form/send");
    setShowSendSuccessModal(false);
  };
  useEffect(() => {
    // console.log("rendered!", state);
    const fetchData = async () => {
      const response = await axios.get(
        `${process.env.REACT_APP_API_SERVER_DOMAIN}/betterthan/participant?pid=${state.pid}&email=${state.email}`
      );

      // if (response.data.participant.friends.length > 0) {
      //   navigate("/form/send");
      // }
      // console.log(response.data.participant);
    };

    fetchData();
  }, []);
  return (
    <FormBox width={"600px"} height={"auto"} style={{ paddingBottom: "120px" }}>
      <Heading>
        <span style={{ fontWeight: "600" }}>{state?.name}</span> 님,
      </Heading>
      <Description>
        긍정 피드백을 요청할 동료(팀장, 팀원, 선후배 등)의 정보를 입력해주세요.
      </Description>
      <List>
        {Array(count)
          .fill(0)
          .map((item, index) => (
            <FriendInput
              key={index}
              friend={friends[index]}
              setFriends={setFriends}
              index={index}
              //   errorNameAt={errorNameAt}
              //   setErrorNameAt={setErrorNameAt}
              errorAt={errorAt}
              setErrorAt={setErrorAt}
              domain={state.domain}
            />
          ))}
      </List>
      <AddButton onClick={handleAddClick}>+</AddButton>
      <Button
        text={"입력 완료"}
        onClick={handleSubmitClick}
        // disabled={!validateFriends()}
      />
      {showModal && (
        <ModalBackground>
          <Modal closeHandler={handleCloseModal} width={"544px"} height="745px">
            <FriendListModal
              friends={finalFriends.filter((f) => f)}
              duplicatedFriends={duplicatedFriends}
              okHandler={handleConfirmClick}
              closeHandler={handleCloseModal}
            />
          </Modal>
        </ModalBackground>
      )}
      {showSendSuccessModal && (
        <ModalBackground>
          <Modal width={"440px"} height="382px">
            <SendSuccessModal
              friends={finalFriends}
              okHandler={sendSuccessConfirm}
              isSending={isSending}
            />
          </Modal>
        </ModalBackground>
      )}
    </FormBox>
  );
};

export default FriendList;
