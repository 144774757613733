import { useMediaQuery } from "react-responsive";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import Layout from "./components/Layout";
import MLayout from "./components/mobile/MLayout";
import Form from "./pages/form/Form";
import FormBirthday from "./pages/form/FormBirthday";
import FormMessage from "./pages/form/FormMessage";
import FormSend from "./pages/form/FormSend";
import FriendList from "./pages/form/FriendList";
import MBirthday from "./pages/mobile/MBirthday";
import MForm from "./pages/mobile/MForm";
import MFormMessage from "./pages/mobile/MFormMessage";
import MFriendList from "./pages/mobile/MFriendList";
import MSend from "./pages/mobile/MSend";
import Report from "./pages/report/Report";
import TinyUrl from "./pages/report/TinyUrl";

const Router = () => {
  const isMobile = useMediaQuery({
    query: "(max-width: 425px)",
  });
  return (
    <BrowserRouter>
      <Routes>
        {isMobile ? (
          <>
            <Route element={<MLayout />}>
              <Route path="/report" element={<Report />} />
              <Route path="/form" element={<MForm />} />
              <Route path="/form/birthday" element={<MBirthday />} />
              <Route path="/form/send" element={<MSend />} />
            </Route>
            <Route element={<MLayout style={{ height: "auto" }} />}>
              <Route path="/form/add" element={<MFriendList />} />
              <Route path="/form/message" element={<MFormMessage />} />
            </Route>
            <Route path="/t/:pid/:uid" element={<TinyUrl />} />
          </>
        ) : (
          <>
            <Route element={<MLayout />}>
              <Route path="/report" element={<Report />} />
            </Route>
            <Route element={<Layout />}>
              <Route path="/form" element={<Form />} />
              <Route path="/form/add" element={<FriendList />} />
              <Route path="/form/birthday" element={<FormBirthday />} />
              <Route path="/form/message" element={<FormMessage />} />
              <Route path="/form/send" element={<FormSend />} />
            </Route>
            {/* <Route element={<Layout style={{ height: "auto" }} />}>
              <Route path="/form/add" element={<FriendList />} />
            </Route> */}
            <Route path="/t/:pid/:uid" element={<TinyUrl />} />
          </>
        )}
      </Routes>
    </BrowserRouter>
  );
};

export default Router;
