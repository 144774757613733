import { Outlet } from "react-router-dom";
import styled from "styled-components";

const Container = styled.div`
  width: 100%;
  //   height: 100vh;
  //   border: 1px solid black;
  background: #f8f8f8;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 40px;
`;

const Layout = () => {
  return (
    <Container>
      <Outlet />
    </Container>
  );
};

export default Layout;
