import { useState } from "react";
import { useRef } from "react";
import { useLocation } from "react-router-dom";
import styled from "styled-components";
import MButton from "../../components/mobile/MButton";
import MDescription from "../../components/mobile/MDescription";
import MFormBox from "../../components/mobile/MFormBox";
import MHeader from "../../components/mobile/MHeader";
import MModal from "../../components/mobile/MModal";
import MSubmitBtn from "../../components/mobile/MSubmitBtn";

const Section = styled.section`
  display: flex;
  justify-content: center;
  width: 100%;
  margin: 30px 0;
  position: relative;
`;

const Image = styled.img`
  width: 16rem;
  height: auto;
`;

const Message = styled.p`
  position: absolute;
  font-size: 0.47rem;
  line-height: 0.8rem;
  font-weight: 400;
  letter-spacing: -0.3px;
  top: 50%;
  transform: translateY(-50%);
  //   border: 1px solid black;
  word-break: keep-all;
  word-wrap: break-word;
  white-space: pre-wrap;
  max-width: 11.5rem;
`;

const ModalHeader = styled.h2`
  color: #111111;
  font-size: 1.44rem;
  font-weight: 600;
  margin-bottom: 15px;
  text-align: center;
  line-height: 1.88rem;
  letter-spacing: -1px;
`;

const ModalText = styled.p`
  font-size: 1rem;
  color: #333333;
  line-height: 1.56rem;
  letter-spacing: -0.5px;
  margin-bottom: 22px;
  text-align: center;
`;
const Svg = styled.svg`
  margin: 0 auto;
  display: block;
  margin-bottom: 17px;
`;

const MFormMessage = () => {
  const messageRef = useRef();
  const [showModal, setShowModal] = useState(false);
  const {
    state: { projectName, link, date },
  } = useLocation();
  const handleClick = async () => {
    // copy to clipboard

    const msg = `안녕하세요 :)
    
저는 파트장이라는 새로운 역할을 인식하고, 다양한 변화에 잘 대응하기 위한 필요역량을 준비하기 위해 ‘${projectName}’에 참여합니다.
    
과정의 일환으로 주변 동료에게 피드백을 받아 성장자원을 탐색하는 시간이 있어 이렇게 설문 작성을 요청드립니다.
    
아래 전달드리는 설문조사 링크에 접속하여 간단한 설문을 부탁드리겠습니다. 

- 설문 링크 : ${link}
- 설문 내용 : 대상자의 강점, 가치, 고마웠던 순간 및 기대하는 역할 등
  ※ 총  8문항, 약 4분 소요
  ※ ~${date} 18:00까지
              
바쁘시겠지만, 잠시 시간을 내어 설문을 진행해주시길 부탁드립니다.
              
당신의 피드백은 동료의 긍정적인 변화의 시작점이 됩니다.
              
감사합니다 :)`;
    try {
      await navigator.clipboard.writeText(msg);
      setShowModal(true);
    } catch (e) {
      alert("복사에 실패하였습니다");
    }
    // show modal
  };
  return (
    <MFormBox>
      <MHeader style={{ fontWeight: 400, marginBottom: "29px" }}>
        설문 요청 예시 문구
      </MHeader>
      <MDescription>
        ‘복사하기’ 버튼을 누르면
        <br />
        페이지 내 문구가 전체 복사됩니다 :)
      </MDescription>
      <Section>
        <Image src={`${process.env.PUBLIC_URL}/assets/chat_layout.png`} />
        <Message ref={messageRef}>
          안녕하세요 :)
          <br />
          <br />
          저는 파트장이라는 새로운 역할을 인식하고, 다양한 변화에 잘 대응하기
          위한 필요역량을 준비하기 위해 ‘{projectName}’에 참여합니다.
          <br />
          <br />
          과정의 일환으로 주변 동료에게 피드백을 받아 성장자원을 탐색하는 시간이
          있어 이렇게 설문 작성을 요청드립니다.
          <br />
          <br />
          아래 전달드리는 설문조사 링크에 접속하여 간단한 설문을
          부탁드리겠습니다.
          <br />
          <br />
          <li>
            <span style={{ fontWeight: "bold" }}>설문 링크 : </span>
            <span style={{ color: "#FF812C" }}>{link}</span>
          </li>
          <li>
            설문 내용 : 대상자의 강점, 가치, 고마웠던 순간 및 기대하는 역할 등
          </li>
          <ol>
            <li style={{ textIndent: 10 }}>※ 총  8문항, 약 4분 소요</li>
            <li style={{ textIndent: 10 }}>※ ~{date} 18:00까지</li>
          </ol>
          <br />
          바쁘시겠지만, 잠시 시간을 내어 설문을 진행해주시길 부탁드립니다.
          <br />
          <br />
          당신의 피드백은 동료의 긍정적인 변화의 시작점이 됩니다.
          <br />
          <br />
          감사합니다 :)
        </Message>
      </Section>
      <MButton type="confirm" onClick={handleClick}>
        복사하기
      </MButton>
      {showModal && (
        <MModal>
          <Svg
            width="50"
            height="40"
            viewBox="0 0 50 40"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M0.9 39.1C1.5 39.7 2.2 40 3 40H26.7V37H3V6.85H0V37C0 37.8 0.3 38.5 0.9 39.1ZM6.9 33.1C7.5 33.7 8.2 34 9 34H26.032L22.9999 31H9V3H31V27.7093L34 30.6776V3C34 2.2 33.7 1.5 33.1 0.9C32.5 0.3 31.8 0 31 0H9C8.2 0 7.5 0.3 6.9 0.9C6.3 1.5 6 2.2 6 3V31C6 31.8 6.3 32.5 6.9 33.1Z"
              fill="#FF812C"
            />
            <path
              d="M27 31.45L35.05 39.5L49.4 25.15L47.25 23L35.05 35.2L29.15 29.3L27 31.45Z"
              fill="#FF812C"
            />
          </Svg>
          <ModalHeader>복사 완료!</ModalHeader>
          <ModalText>
            동료들에게 문자 혹은
            <br />
            카카오톡으로 발송하세요.
          </ModalText>

          <MButton
            type="confirm"
            onClick={() => {
              setShowModal(false);
            }}
          >
            눌러서 마치기
          </MButton>
        </MModal>
      )}
    </MFormBox>
  );
};

export default MFormMessage;
