import styled from "styled-components";
import MDescription from "../../components/mobile/MDescription";
import MFormBox from "../../components/mobile/MFormBox";
import MHeader from "../../components/mobile/MHeader";

const Logo = styled.img`
  width: 55px;
  height: 53px;
  display: block;
  margin: 0 auto;
  margin-top: 90px;
  margin-bottom: 40px;
`;

const MSend = () => {
  return (
    <MFormBox>
      <Logo src={`${process.env.PUBLIC_URL}/assets/logo/logo_orange.png`} />
      <MHeader
        style={{ marginBottom: "50px", lineHeight: "36px", fontWeight: 300 }}
      >
        베러댄피드백 진행을 위한
        <br />
        준비가 모두 끝났습니다.
      </MHeader>
      <MDescription style={{ fontWeight: 300 }}>
        그럼, 기대하는 마음으로
        <br />
        교육일에 만나뵙겠습니다.
        <br />
        감사합니다 :)
      </MDescription>
    </MFormBox>
  );
};

export default MSend;
