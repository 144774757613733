import styled from "styled-components";

const Text = styled.p`
  font-weight: 400;
  font-size: 0.88rem;
  color: ${({ theme }) => theme.error};
`;

const ErrorText = ({ msg, style }) => <Text style={style}>{msg}</Text>;

export default ErrorText;
