import axios from "axios";
import { useState } from "react";
import { useForm } from "react-hook-form";
import { useSearchParams } from "react-router-dom";
import SafeArea from "react-safe-area-component";
import styled from "styled-components";
import MFormBox from "../../components/mobile/MFormBox";
import MLabelInput from "../../components/mobile/MLabelInput";
import MSubmitBtn from "../../components/mobile/MSubmitBtn";
import Spacer from "../../components/Spacer";

const Container = styled.div`
  display: flex;
  justify-content: center;
  height: calc(var(--vh, 1vh) * 100);
`;
const Wrapper = styled.div`
  width: 100%;
  max-width: 425px;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const Logo = styled.img`
  width: 39px;
  height: 39px;
  margin: 0 auto;
  margin-bottom: 11px;
`;
const Title = styled.h1`
  color: ${({ theme }) => theme.orange};
  font-size: 1.59rem;
  font-weight: 700;
  margin: 0 auto;
`;

const Description = styled.p`
  font-size: 0.94rem;
  font-weight: 400;
  line-height: 1.44rem;
  letter-spacing: -0.5px;
  margin-top: 36px;
  margin-bottom: 36px;
  text-align: center;
`;

const Label = styled.label`
  font-size: 0.81rem;
  font-weight: 500;
  color: #111111;
  margin-bottom: 0.5rem;
`;

const Input = styled.input`
  width: 100%;
  height: 3.13rem;
  border-radius: 0.44rem;
  padding-left: 1.06rem;
  border: 1px solid ${({ error }) => (error ? "#D60606" : "#f5f5f5")};
  background: #f5f5f5;
  font-size: 0.81rem;
  margin-bottom: 1.25rem;
`;

const SubmitButton = styled.button`
  width: 100%;
  border-radius: 0.44rem;
  padding: 15px 0;
  border: none;
  display: flex;
  justify-content: center;
  background: #ff812c;
  color: #ffffff;
  font-size: 1rem;
  margin-top: 1.44rem;
  &:disabled {
    background: #ededed;
    color: #c1c1c1;
    cursor: not-allowed;
  }
`;

const ErrorText = styled.p`
  color: #d60606;
  font-size: 0.75rem;
  margin-top: 15px;

  line-height: 1.19rem;
`;

const Report = () => {
  const {
    register,
    handleSubmit,
    watch,
    setError,
    reset,
    formState: { errors },
  } = useForm();
  const [urlSearchParams] = useSearchParams();

  const onSubmit = async (data) => {
    const response = await axios.get(
      `${
        process.env.REACT_APP_API_SERVER_DOMAIN
      }/betterthan/pdf?pid=${urlSearchParams.get("pid")}&name=${
        data.name
      }&birthday=${data.birthday}`
    );

    console.log(response);

    if (response.data.msg === "error") {
      console.log("error!");
      //   setError("name", {
      //     message:
      //       //   "😢 포함되지 않은 대상이거나, 이메일 형식이 올바르지  않습니다. (이메일 대/소문자 구분)",
      //       "😢 포함되지 않은 대상이거나, 생년월일 형식이 올바르지  않습니다. (YYMMDD)",
      //   });
      setError("birthday", {
        message:
          //   "😢 포함되지 않은 대상이거나, 이메일 형식이 올바르지  않습니다. (이메일 대/소문자 구분)",
          "😢 포함되지 않은 대상이거나, 생년월일 형식이 올바르지  않습니다. (YYMMDD)",
      });
    } else {
      setError(null);
      // console.log(response.data.pdfUrl);
      reset();
      window.location.href = response.data.pdfUrl;
    }
  };

  //   const handleSubmit = async () => {
  //     // console.log(window.location.href);
  //     const response = await axios.get(
  //       `${process.env.REACT_APP_API_SERVER_DOMAIN}/betterthan/pdf?email=${email}&name=${name}`
  //     );

  //     // console.log(response);

  //     if (response.data.msg === "error") {
  //       setError(
  //         "😢 포함되지 않은 대상이거나, 이메일 형식이 올바르지  않습니다. (이메일 대/소문자 구분)"
  //       );
  //     } else {
  //       setError(null);
  //       // console.log(response.data.pdfUrl);
  //       window.location.href = response.data.pdfUrl;
  //     }
  //   };

  // useEffect(() => {
  //   const fetch = async () => {
  //     const response = await axios.get(
  //       `http://localhost:8080/betterthan/pdf?email=${email}&name=${name}`
  //     );

  //     console.log(response);
  //   };

  //   fetch();
  // }, []);
  return (
    <SafeArea top bottom>
      <MFormBox
        style={{
          display: "flex",
          justifyContent: "center",
        }}
      >
        <Wrapper>
          <Logo src={`${process.env.PUBLIC_URL}/assets/logo/logo_orange.png`} />
          <Title>베러댄피드백™</Title>
          <Description>
            베러댄피드백 리포트가 도착했습니다☺️
            <br />
            <span style={{ fontWeight: 600 }}>
              이름과 생년월일을 입력하세요.
            </span>
          </Description>
          <form style={{ width: "100%" }} onSubmit={handleSubmit(onSubmit)}>
            <MLabelInput
              labelText={"이름"}
              placeholder="이름을 입력해주세요"
              label="name"
              reset={() => reset({ name: "" })}
              register={register}
              required={{
                required: true,
              }}
              changeHandler={(e) => {
                register("name").onChange(e);
              }}
              watch={() => watch("name")}
              errors={errors}
            />
            <Spacer height={15} />
            {/* <MLabelInput
              labelText={"이메일"}
              placeholder="이메일 주소를 입력해주세요"
              label="email"
              register={register}
              reset={() => reset({ email: "" })}
              required={{
                required: true,
                pattern: {
                  value: /^[^\s@]+@[^\s@]+\.[^\s@]{2,}$/i,
                  message:
                    "😢 포함되지 않은 대상이거나, 이메일 형식이 올바르지 않습니다.",
                },
              }}
              changeHandler={(e) => {
                register("email").onChange(e);
              }}
              watch={() => watch("email")}
              errors={errors}
            /> */}
            <MLabelInput
              labelText={"생년월일"}
              placeholder="YYMMDD"
              label="birthday"
              register={register}
              reset={() => reset({ birthday: "" })}
              required={{
                required: true,
                pattern: {
                  value: /^[0-9]{1,6}$/i,
                  message:
                    "😢 포함되지 않은 대상이거나, 생년월일 형식이 올바르지  않습니다. (YYMMDD)",
                },
              }}
              changeHandler={(e) => {
                register("birthday").onChange(e);
              }}
              watch={() => watch("birthday")}
              errors={errors}
            />

            {errors.birthday && (
              <ErrorText>{errors.birthday.message}</ErrorText>
            )}
            <Spacer height="40" />
            <MSubmitBtn value="리포트 보기" watch={() => watch("birthday")} />
          </form>
        </Wrapper>
      </MFormBox>
    </SafeArea>
  );
};

export default Report;
