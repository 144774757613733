import styled from "styled-components";
import FormBox from "../../components/FormBox";

const Heading = styled.h1`
  font-size: 2rem;
  font-weight: 300;
  color: #111111;
  line-height: 2.81rem;
  letter-spacing: -1px;
  text-align: center;
  margin-top: 70px;
  margin-bottom: 42px;
`;

const Description = styled.p`
  font-size: 1.25rem;
  font-weight: 300;
  color: #333333;
  text-align: center;
  line-height: 2.06rem;
  letter-spacing: -0.5px;
`;

const Logo = styled.img`
  width: 99px;
  height: auto;
  display: block;
  margin: 0 auto;
  margin-top: 96px;
`;

const FormSend = () => {
  return (
    <FormBox logo={false} width={"600px"} height={"688px"}>
      <Logo src={`${process.env.PUBLIC_URL}/assets/logo/logo_orange.png`} />
      <Heading>
        베러댄피드백 진행을 위한
        <br /> 준비가 모두 끝났습니다.
      </Heading>
      <Description>
        그럼, 기대하는 마음으로
        <br />
        교육일에 만나뵙겠습니다. 
        <br />
        감사합니다 :)
      </Description>
    </FormBox>
  );
};
export default FormSend;
