import styled from "styled-components";

const Button = styled.button`
  width: 100%;
  display: flex;
  padding: 18px 0;
  justify-content: center;
  border: none;
  border-radius: 0.63rem;
  font-size: 1.13rem;
  background: ${({ theme, type }) =>
    type === "confirm" ? theme.btnBg : theme.btnBgCancel};
  color: ${({ type }) => (type === "confirm" ? "white" : "#111111")};
  &:disabled {
    background: ${({ theme }) => theme.btnBgDisabled};
  }
  &:hover {
    background: ${({ theme, type }) => type === "confirm" && theme.btnBgHover};
  }
  transition: all 0.3s;
`;

const MButton = ({ children, type, onClick, style, disabled }) => {
  return (
    <Button style={style} type={type} onClick={onClick} disabled={disabled}>
      {children}
    </Button>
  );
};

export default MButton;
