import { useState } from "react";
import styled from "styled-components";
import ClearBtn from "../ClearBtn";

const Container = styled.div`
  width: 100%;
  border-radius: 0.94rem;
  border: 1px solid ${({ borderColor }) => borderColor || "#D8D8D8"};
  padding: 16px 21px;
  display: flex;
  flex-direction: column;
  position: relative;
`;

const Label = styled.label`
  color: #bbbbbb;
  font-size: 0.81rem;
  font-weight: 500;
  line-height: 16px;
  letter-spacing: -0.5px;
  margin-bottom: 6px;
`;
const Input = styled.input`
  border: none;
  font-size: 1.06rem;
  color: #111111;
  line-height: 20px;
  font-weight: 400;
  &:placeholder {
    color: #bbbbbb;
  }
  padding: 0;
`;

const MLabelInput = ({
  labelText,
  placeholder,
  type = "text",
  inputMode,
  label,
  register,
  required,
  reset,
  changeHandler,
  keydownHandler,

  watch,
  errors,
}) => {
  const [borderColor, setBorderColor] = useState("#D8D8D8");
  // console.log(watch());

  return (
    <Container borderColor={errors[label] ? "#ED2B2B" : borderColor}>
      <Label>{labelText}</Label>
      <Input
        type={type}
        inputMode={inputMode}
        placeholder={placeholder}
        {...register(label, {
          ...required,
        })}
        onChange={changeHandler}
        onKeyDown={keydownHandler}
        onFocus={() => setBorderColor("#111111")}
        onBlur={() => setBorderColor("#D8D8D8")}
      />
      {watch()?.length > 0 && (
        <ClearBtn style={{ bottom: "16px", right: "16px" }} onClick={reset} />
      )}
    </Container>
  );
};

export default MLabelInput;
