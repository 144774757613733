import { useEffect, useState } from "react";
import styled from "styled-components";
import { validateEmail } from "../utils";
import ErrorText from "./ErrorText";
import Input from "./Input";

const Container = styled.div`
  display: flex;
  width: 100%;
  height: 40px;
  align-items: center;
  margin-bottom: 15px;
  //   border: 1px solid black;
`;

const Avatar = styled.img`
  width: 40px;
  height: 40px;
  margin-right: 22px;
`;

const FriendInput = ({
  friend,
  setFriends,
  index,
  errorNameAt,
  setErrorNameAt,
  errorAt,
  setErrorAt,
  domain,
}) => {
  const [email, setEmail] = useState("");
  const [name, setName] = useState("");
  const [isValid, setIsValid] = useState(false);
  const [error, setError] = useState("");
  const [isRemoving, setIsRemoving] = useState(false);

  const handleEmailKeyDown = (e) => {
    if (
      e.key === "Backspace" &&
      e.target.value[e.target.value.length - 2] === "@"
    ) {
      setEmail(e.target.value.substring(0, e.target.value.length));
      setIsRemoving(true);
    }
  };

  const handleNameChange = (e) => {
    setErrorNameAt();
    setName(e.target.value);
    setFriends((cur) => {
      cur[index] = {
        ...cur[index],
        name: e.target.value,
      };
      return cur;
    });
  };
  const handleEmailChange = (e) => {
    setErrorAt();
    if (
      (e.target.value.match(/@/g) || []).length === 1 &&
      !isRemoving &&
      e.target.value[e.target.value.length - 1] === "@"
    ) {
      setEmail(e.target.value + domain);
      setFriends((cur) => {
        cur[index] = {
          ...cur[index],
          email: e.target.value + domain,
        };
        return cur;
      });
    } else {
      setEmail(e.target.value.toLowerCase());
      setFriends((cur) => {
        cur[index] = {
          ...cur[index],
          email: e.target.value.toLowerCase(),
        };
        return cur;
      });
      setIsRemoving(false);
    }
  };

  //   const handleNameBlur = (e) => {
  //     if (e.target.value === "") {
  //       setError("😢 이름을 입력해주세요.");
  //     }
  //   };

  const handleEmailBlur = (e) => {
    if (e.target.value !== "") {
      setIsValid(validateEmail(e.target.value));
      if (!validateEmail(e.target.value)) {
        setError("😢 이메일 형식이 올바르지 않습니다.");
      }
    } else {
      //   if (name?.length > 0) {
      //     setError("😢 이메일 형식이 올바르지 않습니다.");
      //   }
    }
  };
  return (
    <>
      <Container>
        <Avatar
          src={
            isValid && email.length > 0
              ? `${process.env.PUBLIC_URL}/assets/avatar_active.png`
              : `${process.env.PUBLIC_URL}/assets/avatar.png`
          }
        />
        {/* <Input
          type="text"
          placeholder={"이름"}
          value={name}
          onChange={handleNameChange}
          setValue={() => {
            setName("");
            setFriends((cur) => {
              cur[index] = {
                ...cur[index],
                name: "",
              };
              return cur;
            });
          }}
          // onBlur={handleNameBlur}
          // error={error}
          style={{
            width: "123px",
            marginRight: "10px",
            borderColor: errorNameAt?.index === index && "#D60606",
          }}
        /> */}
        <Input
          type="text"
          placeholder={"이메일 주소"}
          onChange={handleEmailChange}
          onKeyDown={handleEmailKeyDown}
          onBlur={handleEmailBlur}
          setError={setError}
          value={email}
          setValue={() => {
            setEmail("");
            setFriends((cur) => {
              cur[index] = {
                ...cur[index],
                email: "",
              };
              return cur;
            });
          }}
          error={error}
          style={{
            // width: "18.94rem",
            borderColor:
              (error.length > 0 && "#D60606") ||
              (errorAt?.index === index && "#D60606"),
          }}
        />
      </Container>
      {error && (
        <ErrorText
          msg={error}
          style={{
            marginLeft: "62px",
            marginBottom: "16px",
          }}
        />
      )}
    </>
  );
};

export default FriendInput;
