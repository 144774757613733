import styled from "styled-components";

const Description = styled.p`
  font-size: 15px;
  font-weight: 400;
  color: #333333;
  letter-spacing: -0.5px;
  line-height: 24px;
  text-align: center;
`;

const MDescription = ({ style, children }) => {
  return <Description style={style}>{children}</Description>;
};

export default MDescription;
