import styled from "styled-components";
import { DotWave } from "@uiball/loaders";

const SButton = styled.button`
  width: ${({ width }) => width || "100%"};
  height: 4.06rem;
  font-size: 1.25rem;
  line-height: 2.19rem;
  letter-spacing: -0.5px;
  color: ${({ type }) => (type === "cancel" ? "#111111" : "white")};
  font-weight: 400;
  border-radius: 0.63rem;
  background: ${({ theme, type }) =>
    type === "cancel" ? theme.btnBgCancel : theme.btnBg};
  display: flex;
  justify-content: center;
  align-items: center;
  border: none;
  &:hover {
    background: ${({ theme, type }) => type !== "cancel" && theme.btnBgHover};
  }
  &:disabled {
    background: ${({ theme }) => theme.btnBgDisabled};
  }
  transition: all 0.3s;
`;

const Button = ({
  text,
  type,
  disabled,
  onClick,
  width,
  isSubmitting = false,
}) => {
  return (
    <SButton disabled={disabled} onClick={onClick} type={type} width={width}>
      {isSubmitting ? <DotWave size={47} speed={1} color="white" /> : text}
    </SButton>
  );
};

export default Button;
