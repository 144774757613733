import styled from "styled-components";

const Text = styled.p`
  font-size: 0.88rem;
  line-height: 1.19rem;
  color: ${({ theme }) => theme.error};
  font-weight: 400;
  letter-spacing: -0.5px;
`;

const MErrorText = ({ children, style }) => {
  return <Text style={style}>{children}</Text>;
};

export default MErrorText;
