const theme = {
  orange: "#FF812C",
  error: "#D60606",
  btnBg: "#FF812C",
  btnBgHover: "#F06100",
  btnBgDisabled: "#EDEDED",
  btnBgCancel: "#EEEEEE",
  placeholderColor: "#BBBBBB",
};

export default theme;
