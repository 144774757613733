import axios from "axios";
import { useState } from "react";
import { useForm } from "react-hook-form";
import { useNavigate, useSearchParams } from "react-router-dom";
import styled from "styled-components";
import MDescription from "../../components/mobile/MDescription";
import MErrorText from "../../components/mobile/MErrorText";
import MFormBox from "../../components/mobile/MFormBox";
import MLabelInput from "../../components/mobile/MLabelInput";
import MSubmitBtn from "../../components/mobile/MSubmitBtn";
import Spacer from "../../components/Spacer";
import { getEmailDomain } from "../../utils";

const Header = styled.h1`
  color: ${({ theme }) => theme.orange};
  font-size: 1.44rem;
  line-height: 2.88rem;
  letter-spacing: -1px;
  font-weight: 600;
  text-align: center;
`;

const MForm = () => {
  const [searchParams, setSearchParams] = useSearchParams();
  const {
    register,
    handleSubmit,
    watch,
    reset,
    setValue,
    setError,
    formState: { errors, isSubmitting },
  } = useForm({
    mode: "onBlur",
    defaultValues: { email: searchParams.get("email") || "" },
  });
  const navigate = useNavigate();
  const [isRemoving, setIsRemoving] = useState(false);
  const domain = getEmailDomain(searchParams.get("email") || "");

  const handleInputChange = (e) => {
    // console.log("Key Change", e.target.value);
    // setError("");
    if (
      (e.target.value.match(/@/g) || []).length === 1 &&
      !isRemoving &&
      e.target.value[e.target.value.length - 1] === "@"
    ) {
      //   console.log("add @");
      setValue("email", e.target.value + domain);
    } else {
      setValue("email", e.target.value);
      setIsRemoving(false);
    }
  };
  const handleKeyDown = (e) => {
    // console.log("Key Down", e.key, e.target.value);
    if (
      e.key === "Backspace" &&
      e.target.value[e.target.value.length - 2] === "@"
    ) {
      setValue("email", e.target.value.substring(0, e.target.value.length));
      setIsRemoving(true);
    }
  };

  const onSubmit = async (data) => {
    // console.log(data.email);
    // console.log(isSubmitting);
    try {
      //   console.log(data);
      const response = await axios.get(
        `${
          process.env.REACT_APP_API_SERVER_DOMAIN
        }/betterthan/participant?pid=${searchParams.get("pid")}&email=${
          data.email
        }`
      );
      // if (response.data.participant.friends.length !== 0) {
      //   setError("email", { message: "이미 등록을 하셨습니다." });
      //   // setBorderColor("#D60606");
      //   // setIsSending(false);
      //   return;
      // }
      navigate("/form/birthday", {
        state: {
          pid: response.data.participant.pid,
          email: response.data.participant.email,
          name: response.data.participant.name,
          //   friends: friends.filter((f) => f).length,
        },
      });
    } catch (err) {
      console.log(err);
      setError("email", { message: err.response.data.msg });
    }
  };

  return (
    <MFormBox>
      <Header style={{ marginBottom: "12px", lineHeight: "46px" }}>
        베러댄피드백
      </Header>
      <MDescription style={{ marginBottom: "48px" }}>
        안녕하세요☺️
        <br />
        본인 확인을 위해{" "}
        <span style={{ fontWeight: 600 }}>회사 이메일 주소</span>를
        입력해주세요.
      </MDescription>
      <form onSubmit={handleSubmit(onSubmit)}>
        <MLabelInput
          labelText="회사 이메일"
          placeholder="abcd@bloom.company"
          label={"email"}
          register={register}
          reset={() => reset({ email: "" })}
          required={{
            required: true,
            pattern: {
              value: /^[^\s@]+@[^\s@]+\.[^\s@]{2,}$/i,
              message:
                "😢 포함되지 않은 대상이거나, 이메일 형식이 올바르지 않습니다.",
            },
          }}
          changeHandler={handleInputChange}
          keydownHandler={handleKeyDown}
          setValue={setValue}
          watch={() => watch("email")}
          errors={errors}
        />
        {errors.email && (
          <MErrorText style={{ marginTop: "12px" }}>
            {errors.email.message}
          </MErrorText>
        )}
        <Spacer height={"50"} />
        <MSubmitBtn
          value={"입력 완료"}
          watch={() => watch("email")}
          isSubmitting={isSubmitting}
        />
      </form>
    </MFormBox>
  );
};

export default MForm;
