import styled from "styled-components";

const Container = styled.div`
  width: ${({ width }) => width || "600"};
  height: ${({ height }) => `${height}` || "100%"};
  border-radius: 3.13rem;
  padding: 50px;
  background: white;
  position: relative;
`;

const CloseBtn = styled.img`
  position: absolute;
  width: 18px;
  height: 18px;
  top: 2.56rem;
  right: 2.56rem;
  cursor: pointer;
`;

const Modal = ({ width, height, closeHandler, children }) => {
  return (
    <Container width={width} height={height}>
      {children}
      {closeHandler && (
        <CloseBtn
          src={`${process.env.PUBLIC_URL}/assets/close.png`}
          onClick={closeHandler}
        />
      )}
    </Container>
  );
};

export default Modal;
