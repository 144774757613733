import styled from "styled-components";
import { DotWave } from "@uiball/loaders";

const Container = styled.div`
  position: relative;
`;

const Loader = styled.div`
  position: absolute;
  //   display: flex;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);

  //   align-items: center;
  //   justify-content: center;
`;
const Input = styled.input.attrs({ type: "submit" })`
  width: 100%;
  border: none;
  padding: 18px 0;
  display: flex;
  justify-content: center;
  font-size: 1.13rem;
  border-radius: 0.63rem;
  color: white;
  -webkit-appearance: none;
  background: ${({ theme, disabled }) =>
    disabled ? theme.btnBgDisabled : theme.orange};
  &:hover {
    background: ${({ theme, disabled }) =>
      disabled ? theme.btnBgDisabled : theme.btnBgHover};
  }

  transition: all 0.3s;
`;

const MSubmitBtn = ({ value, watch, isSubmitting = false }) => {
  return (
    <Container>
      <Input
        type="submit"
        value={isSubmitting ? "" : value}
        disabled={!watch() ? true : false}
      />
      {isSubmitting && (
        <Loader>
          <DotWave size={47} speed={1} color="white" />
        </Loader>
      )}
    </Container>
  );
};

export default MSubmitBtn;
