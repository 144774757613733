import axios from "axios";
import { useEffect, useState } from "react";
import { useFieldArray, useForm, Controller } from "react-hook-form";
import { useLocation, useNavigate } from "react-router-dom";
import { DotWave } from "@uiball/loaders";
import styled from "styled-components";
import AddBtn from "../../components/AddBtn";
import ClearBtn from "../../components/ClearBtn";
import MBottomView from "../../components/mobile/MBottomView";
import MButton from "../../components/mobile/MButton";
import MDescription from "../../components/mobile/MDescription";
import MErrorText from "../../components/mobile/MErrorText";
import MFormBox from "../../components/mobile/MFormBox";
import MHeader from "../../components/mobile/MHeader";
import MModal from "../../components/mobile/MModal";
import MSubmitBtn from "../../components/mobile/MSubmitBtn";

const FriendList = styled.ul`
  //   overflow-y: scroll;
  //   height: 200px;
`;
const FriendItem = styled.li`
  border: 1px solid
    ${({ theme, error, initialize }) =>
      error ? theme.error : initialize ? "#D8D8D8" : "#F7f7f7"};
  border-radius: 0.75rem;
  padding: 20px;
  width: 100%;
  margin-bottom: 15px;
  background: ${({ error, initialize }) =>
    initialize ? "white" : error ? "white" : "#F9F9F9"};
`;

const InputContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  align-items: flex-start;
  position: relative;
  //   padding: 20px;
`;

const InputSeparator = styled.div`
  width: 100%;
  height: 1px;
  background: #d8d8d8;
  margin: 15px 0;
`;

const InputLabel = styled.label`
  font-size: 0.81rem;
  font-weight: 500;
  color: ${({ theme }) => theme.placeholderColor};
  letter-spacing: -0.5px;
  position: absolute;
  left: 21px;
  top: 16px;
`;

const Input = styled.input`
  border-radius: 0.75rem;
  padding: 20px;
  padding-top: 36px;
  width: 100%;
  margin-bottom: 15px;
  border: 1px solid
    ${({ theme, error, borderColor }) => (error ? theme.error : borderColor)};
  font-size: 1.06rem;
  font-weight: 400;
  flex: 5;
  background: ${({ theme, background, error }) =>
    error ? "white" : background};
  &:placeholder {
    color: ${({ theme, error }) =>
      error ? theme.error : theme.placeholderColor};
  }
  &:focus {
    border: 1px solid #111111;
    background: white;
  }
  &:blur {
    border: 1px solid blue;
  }
`;

const Loading = styled.div`
  display: flex;
  justify-content: center;
  margin-bottom: 20px;
`;

const EmailSvg = styled.svg`
  margin: 0 auto;
  display: block;
  margin-bottom: 19px;
`;

const MFriendList = () => {
  const { state } = useLocation();
  const {
    register,
    control,
    handleSubmit,
    reset,
    watch,
    setValue,
    clearErrors,
    formState: { errors },
  } = useForm({
    mode: "onBlur",
    reValidateMode: "onSubmit",
    defaultValues: {
      friend: [
        { email: "" },
        { email: "" },
        { email: "" },
        { email: "" },
        { email: "" },
      ],
    },
  });
  const { ref, ...rest } = register("friend");
  const [isSending, setIsSending] = useState(false);
  const [friends, setFriends] = useState([]);
  const [showBottomView, setShowBottomView] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [borderColor, setBorderColor] = useState([]);
  const [duplicatedFriends, setDuplicatedFriends] = useState([]);
  const { fields, append, prepend, remove, swap, move, insert } = useFieldArray(
    {
      control,
      name: "friend",
    }
  );
  console.log();
  const navigate = useNavigate();

  const handleInputChange = (e, index) => {
    if (!e.nativeEvent.data) {
      setValue(`friend[${index}].email`, e.target.value);
    } else if (
      (e.target.value.match(/@/g) || []).length === 1 &&
      e.target.value[e.target.value.length - 1] === "@"
    ) {
      setValue(`friend[${index}].email`, e.target.value + state.domain);
    } else {
      setValue(`friend[${index}].email`, e.target.value.toLowerCase());
    }
  };

  const goNextClick = () => {
    navigate("/form/send");
  };

  const onSubmit = async (data) => {
    if (data.friend.filter((item) => item.email.length > 0).length > 0) {
      const filteredFriends = data.friend.filter(
        (item) => item.email.length > 0
      );
      const response = await axios.get(
        `${process.env.REACT_APP_API_SERVER_DOMAIN}/betterthan/friends`,
        {
          params: {
            friends: filteredFriends,
            pid: state.pid,
            email: state.email,
          },
        }
      );
      setDuplicatedFriends(response.data.duplicatedFriends);
      setShowBottomView(true);
      setFriends(filteredFriends);
    }
  };

  const confirmHandler = async () => {
    setIsSending(true);
    setShowModal(true);
    const respone = await axios.post(
      `${process.env.REACT_APP_API_SERVER_DOMAIN}/betterthan/friends`,
      {
        friends,
        pid: state.pid,
        email: state.email,
      }
    );
    setIsSending(false);
  };

  useEffect(() => {
    console.log("rendered!", state);
    const fetchData = async () => {
      const response = await axios.get(
        `${process.env.REACT_APP_API_SERVER_DOMAIN}/betterthan/participant?pid=${state.pid}&email=${state.email}`
      );
      // if (response.data.participant.friends.length > 0) {
      //   navigate("/form/send");
      // }
      // console.log(response.data.participant);
    };

    fetchData();
  }, []);

  return (
    <MFormBox>
      <MHeader style={{ marginBottom: "12px" }}>
        <span style={{ fontWeight: 600 }}>{state.name}</span> 님,
      </MHeader>
      <MDescription style={{ marginBottom: "37px" }}>
        긍정피드백을 요청할 동료(팀장, 팀원, 선후배 등)의
        <br />
        정보를 입력해주세요.
      </MDescription>
      <form onSubmit={handleSubmit(onSubmit)}>
        <FriendList>
          {fields.map((item, index) => {
            // console.log(item);
            // console.log(register("friend"));
            return (
              <div key={item.id}>
                {/* <FriendItem
                  borderColor={borderColor}
                  error={errors.friend && errors.friend[index] ? true : false}
                  initialize={
                    watch("friend") && watch("friend")[index].email === ""
                      ? true
                      : false
                  }
                > */}
                <InputContainer>
                  <InputLabel>이메일 주소</InputLabel>
                  <Input
                    borderColor={
                      watch("friend")[index]?.email.length > 0
                        ? "#f7f7f7"
                        : "#d8d8d8"
                    }
                    background={
                      watch("friend")[index]?.email.length > 0
                        ? "#f7f7f7"
                        : "white"
                    }
                    {...rest}
                    name={`friend[${index}].email`}
                    placeholder="kim@bloom.company"
                    defaultValue={item.email} // make sure to set up defaultValue
                    {...register(`friend.${index}.email`, {
                      required: false,
                      pattern: {
                        value: /^[^\s@]+@[^\s@]+\.[^\s@]{2,}$/i,
                        message: "😢 이메일 형식이 올바르지 않습니다.",
                      },
                    })}
                    error={errors.friend && errors.friend[index] ? true : false}
                    onChange={(e) => {
                      register("friend").onChange(e);
                      clearErrors(`friend[${index}].email`);
                      handleInputChange(e, index);
                    }}
                  />
                  {watch("friend") &&
                    watch("friend")[index]?.email?.length > 0 && (
                      <ClearBtn
                        style={{ bottom: "37px", right: "19px" }}
                        onClick={() => {
                          const friends = watch("friend");
                          friends[index].email = "";
                          reset({
                            friend: friends,
                          });
                        }}
                      />
                    )}
                </InputContainer>
                {/* </FriendItem> */}
                {errors.friend && errors.friend[index] && (
                  <MErrorText style={{ marginBottom: "15px" }}>
                    {errors?.friend[index]?.email.message}
                  </MErrorText>
                )}
              </div>
            );
          })}
        </FriendList>
        <AddBtn
          onClick={() => {
            append({ email: "" });
          }}
        />
        <MSubmitBtn value="입력 완료" watch={() => watch("friend")} />
      </form>
      {showBottomView && (
        <MBottomView
          friends={friends}
          duplicatedFriends={duplicatedFriends}
          cancelHandler={() => setShowBottomView(false)}
          confirmHandler={async () => {
            await confirmHandler();
          }}
        ></MBottomView>
      )}
      {showModal && (
        <MModal>
          {isSending ? (
            <Loading>
              <DotWave size={47} speed={1} color="#FF812C" />
            </Loading>
          ) : (
            <EmailSvg
              width="42"
              height="36"
              viewBox="0 0 42 36"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M27.65 36L19.6 27.95L21.75 25.8L27.65 31.7L39.85 19.5L42 21.65L27.65 36ZM19.9 13.65L36.6 3H3.2L19.9 13.65ZM19.9 16.65L3 5.8V28.4H15.8L18.8 31.4H3C2.2 31.4 1.5 31.1 0.9 30.5C0.3 29.9 0 29.2 0 28.4V3C0 2.2 0.3 1.5 0.9 0.9C1.5 0.3 2.2 0 3 0H36.85C37.65 0 38.35 0.3 38.95 0.9C39.55 1.5 39.85 2.2 39.85 3V15.2L36.85 18.2V5.8L19.9 16.65Z"
                fill="#FF812C"
              />
            </EmailSvg>
          )}
          <MHeader
            style={{
              fontSize: "1.44rem",
              fontWeight: 600,
              marginBottom: "15px",
            }}
          >
            {isSending ? "메일을 보내고 있어요" : "메일이 발송되었어요"}
          </MHeader>
          {isSending ? (
            <MDescription style={{ fontSize: "1rem", marginBottom: "32px" }}>
              설문 요청 메일을 발송 중입니다.
              <br />
              잠시만 기다려 주세요.
            </MDescription>
          ) : (
            <MDescription style={{ fontSize: "1rem", marginBottom: "32px" }}>
              입력하신 {friends.length}명에게 성공적으로
              <br />
              설문 요청 메일을 발송하였습니다!
            </MDescription>
          )}
          <MButton type="confirm" onClick={goNextClick} disabled={isSending}>
            눌러서 확인하기
          </MButton>
        </MModal>
      )}
    </MFormBox>
  );
};

export default MFriendList;
