import styled from "styled-components";

const P = styled.p`
  font-size: 1.13rem;
  font-weight: 300;
  color: #333333;
  text-align: center;
  line-height: 1.75rem;
  letter-spacing: -0.5px;
`;

const Description = ({ children, style }) => {
  return <P style={style}>{children}</P>;
};

export default Description;
