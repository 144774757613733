import styled from "styled-components";

const Svg = styled.svg`
  position: absolute;
`;

const ClearBtn = ({ style, onClick }) => {
  return (
    <Svg
      onClick={onClick}
      style={style}
      width="18"
      height="18"
      viewBox="0 0 18 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle cx="9" cy="9" r="9" fill="#D8D8D8" />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M5.46967 5.46967C5.76256 5.17678 6.23744 5.17678 6.53033 5.46967L12.5303 11.4697C12.8232 11.7626 12.8232 12.2374 12.5303 12.5303C12.2374 12.8232 11.7626 12.8232 11.4697 12.5303L5.46967 6.53033C5.17678 6.23744 5.17678 5.76256 5.46967 5.46967Z"
        fill="white"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12.5303 5.46967C12.2374 5.17678 11.7626 5.17678 11.4697 5.46967L5.46967 11.4697C5.17678 11.7626 5.17678 12.2374 5.46967 12.5303C5.76256 12.8232 6.23744 12.8232 6.53033 12.5303L12.5303 6.53033C12.8232 6.23744 12.8232 5.76256 12.5303 5.46967Z"
        fill="white"
      />
    </Svg>
  );
};

export default ClearBtn;
