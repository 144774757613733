import styled from "styled-components";

const Header = styled.h1`
  font-weight: 400;
  font-size: 26px;
  color: #111111;
  text-align: center;
  line-height: 30px;
  letter-spacing: -1px;
`;

const MHeader = ({ style, children }) => {
  return <Header style={style}>{children}</Header>;
};

export default MHeader;
