import styled from "styled-components";
import Button from "../../components/Button";

const Container = styled.div`
  padding: 10px 10px 10px 10px;
`;

const IconSvg = styled.svg`
  margin: 0 auto;
  display: block;
  margin-bottom: 10px;
`;

const Heading = styled.h1`
  font-size: 1.88rem;
  font-weight: 300;
  color: #111111;
  line-height: 2.88rem;
  letter-spacing: -1px;
  text-align: center;
`;

const Description = styled.p`
  font-size: 1rem;
  font-weight: 300;
  color: #333333;
  text-align: center;
  line-height: 1.75rem;
  letter-spacing: -0.5px;
  //   margin-bottom: 61px;
`;
const MessageCopyModal = ({ okHandler }) => {
  return (
    <Container>
      <IconSvg
        width="50"
        height="40"
        viewBox="0 0 50 40"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M0.9 39.1C1.5 39.7 2.2 40 3 40H26.7V37H3V6.85H0V37C0 37.8 0.3 38.5 0.9 39.1ZM6.9 33.1C7.5 33.7 8.2 34 9 34H26.032L22.9999 31H9V3H31V27.7093L34 30.6776V3C34 2.2 33.7 1.5 33.1 0.9C32.5 0.3 31.8 0 31 0H9C8.2 0 7.5 0.3 6.9 0.9C6.3 1.5 6 2.2 6 3V31C6 31.8 6.3 32.5 6.9 33.1Z"
          fill="#FF812C"
        />
        <path
          d="M27 31.45L35.05 39.5L49.4 25.15L47.25 23L35.05 35.2L29.15 29.3L27 31.45Z"
          fill="#FF812C"
        />
      </IconSvg>
      <Heading
        style={{
          fontSize: "1.44rem",
          fontWeight: 600,
          marginBottom: "11px",
        }}
      >
        복사 완료!
      </Heading>
      <Description style={{ fontSize: "1rem", marginBottom: "32px" }}>
        동료들에게 문자 혹은
        <br />
        카카오톡으로 발송하세요.
      </Description>

      <Button type="confirm" onClick={okHandler} text={"눌러서 마치기"} />
    </Container>
  );
};

export default MessageCopyModal;
