export const validateEmail = (email) => {
  if (/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,10})+$/.test(email)) {
    return true;
  }

  return false;
};

export const getEmailDomain = (email) => {
  return email.slice(email.indexOf("@") + 1);
};

export const convertDay = (date) => {
  let day;
  switch (new Date(date).getDay()) {
    case 0:
      day = "일";
      break;
    case 1:
      day = "월";
      break;
    case 2:
      day = "화";
      break;
    case 3:
      day = "수";
      break;
    case 4:
      day = "목";
      break;
    case 5:
      day = "금";
      break;
    case 6:
      day = "토";
      break;
  }

  return day;
};
