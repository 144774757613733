import styled from "styled-components";
import Button from "../../components/Button";

const Container = styled.div``;

const Heading = styled.h1`
  font-size: 1.38rem;
  font-weight: 300;
  color: #111111;
  line-height: 1.38rem;
  letter-spacing: -1px;
  text-align: center;
  margin-top: 10px;
  margin-bottom: 15px;
`;

const Description = styled.p`
  font-size: 1.13rem;
  font-weight: 400;
  color: ${({ theme }) => theme.orange};
  text-align: center;
  line-height: 1.88rem;
  letter-spacing: -0.5px;
  margin-bottom: 36px;
`;

const ButtonContainer = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
`;

const BirthdayText = styled.p`
  font-size: 3.75rem;
  font-weight: 700;
  text-align: center;
  margin-bottom: 55px;
`;

const BirthdayModal = ({ okHandler, closeHandler, birthday }) => {
  return (
    <Container>
      <Heading>화면에 표시된 숫자가 내 생년월일이 맞나요?</Heading>
      <Description>
        🔒 리포트 확인을 위한 비밀번호이니 꼭 기억해 주세요!
      </Description>
      <BirthdayText>{birthday}</BirthdayText>
      <ButtonContainer>
        <Button
          text={"다시 입력하기"}
          type="cancel"
          onClick={closeHandler}
          width={"214px"}
        />
        <Button text={"확인 완료"} onClick={okHandler} width={"214px"} />
      </ButtonContainer>
    </Container>
  );
};

export default BirthdayModal;
