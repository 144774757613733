import axios from "axios";
import { useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import styled from "styled-components";
import Button from "../../components/Button";
import ErrorText from "../../components/ErrorText";
import FormBox from "../../components/FormBox";
import LabelInput from "../../components/LabelInput";
import Spacer from "../../components/Spacer";
import { getEmailDomain } from "../../utils";

const Heading = styled.h1`
  font-size: 1.44rem;
  font-weight: 600;
  color: ${({ theme }) => theme.orange};
  line-height: 2.88rem;
  letter-spacing: -1px;
  text-align: center;
  margin-top: 66px;
  margin-bottom: 24px;
`;

const Description = styled.p`
  font-size: 1rem;
  font-weight: 400;
  color: #333333;
  text-align: center;
  line-height: 1.75rem;
  letter-spacing: -0.5px;
  margin-bottom: 61px;
`;

const Form = () => {
  const [searchParams, setSearchParams] = useSearchParams();
  const [isSending, setIsSending] = useState(false);
  const [inputs, setInputs] = useState(5);
  const [friends, setFriends] = useState(Array(inputs).fill(""));
  const [isRemoving, setIsRemoving] = useState(false);
  const [value, setValue] = useState(searchParams.get("email") || "");
  const [error, setError] = useState("");
  const [borderColor, setBorderColor] = useState(
    error?.length > 0 ? "#D60606" : "#D8D8D8"
  );
  const navigate = useNavigate();
  const domain = getEmailDomain(searchParams.get("email") || "");

  const handleInputChange = (e) => {
    // console.log("Key Change", e.target.value);
    // setError("");
    if (
      (e.target.value.match(/@/g) || []).length === 1 &&
      !isRemoving &&
      e.target.value[e.target.value.length - 1] === "@"
    ) {
      //   console.log("add @");
      setValue(e.target.value + domain);
    } else {
      setValue(e.target.value);
      setIsRemoving(false);
    }
  };
  const handleKeyDown = (e) => {
    // console.log("Key Down", e.key, e.target.value);
    if (
      e.key === "Backspace" &&
      e.target.value[e.target.value.length - 2] === "@"
    ) {
      setValue(e.target.value.substring(0, e.target.value.length));
      setIsRemoving(true);
    }
  };

  const handleInputFocus = () => {
    setBorderColor("#111111");
    setError("");
  };

  const handleOnBlur = (e) => {
    setBorderColor("#D8D8D8");
  };
  const handleSendClick = async () => {
    setIsSending(true);
    let error = "";
    try {
      const response = await axios.get(
        `${
          process.env.REACT_APP_API_SERVER_DOMAIN
        }/betterthan/participant?pid=${searchParams.get("pid")}&email=${value}`
      );

      // if (response.data.participant.friends.length !== 0) {
      //   setError("이미 등록을 하셨습니다.");
      //   setBorderColor("#D60606");
      //   setIsSending(false);
      //   return;
      // }

      navigate("/form/birthday", {
        state: {
          pid: response.data.participant.pid,
          email: response.data.participant.email,
          name: response.data.participant.name,
          //   friends: friends.filter((f) => f).length,
        },
      });
    } catch (err) {
      error = "😢 포함되지 않은 대상이거나, 이메일 형식이 올바르지 않습니다.";

      setError(error);
      setBorderColor("#D60606");

      setIsSending(false);
    }
  };

  return (
    <FormBox width="37.5rem" height="46.88rem">
      <Heading>베러댄피드백</Heading>
      <Description>
        안녕하세요☺️
        <br />
        본인 확인을 위해{" "}
        <span style={{ fontWeight: 600 }}>회사 이메일 주소</span>를
        입력해주세요.
      </Description>
      <LabelInput
        placeholder={"abcd@bloom.company"}
        type="text"
        label={"회사 이메일"}
        value={value}
        setValue={setValue}
        onChange={handleInputChange}
        onKeyDown={handleKeyDown}
        onFocus={handleInputFocus}
        onBlur={handleOnBlur}
        borderColor={borderColor}
        error={error}
      />
      {error && <ErrorText msg={error} style={{ marginTop: "19px" }} />}
      {/* {friends.map((item, index) => {
          return (
            <input
              key={index}
              type="email"
              id={index}
              onChange={handleEmailChange}
            />
          );
        })} */}
      <Spacer height="58" />
      <Button
        onClick={handleSendClick}
        text={"입력 완료"}
        disabled={value.length === 0}
        isSubmitting={isSending}
      />
    </FormBox>
  );
};

export default Form;
