import axios from "axios";
import { useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import styled from "styled-components";
import Button from "../../components/Button";
import ErrorText from "../../components/ErrorText";
import FormBox from "../../components/FormBox";
import LabelInput from "../../components/LabelInput";
import Modal from "../../components/Modal";
import ModalBackground from "../../components/ModalBackground";
import Spacer from "../../components/Spacer";
import { FEEDBACK_TYPE } from "../../constants";
import { convertDay, getEmailDomain } from "../../utils";
import BirthdayModal from "./BirthdayModal";

const Heading = styled.h1`
  font-size: 1.88rem;
  font-weight: 600;
  color: #111111;
  line-height: 2.44rem;
  letter-spacing: -1px;
  text-align: center;
  margin-top: 66px;
  margin-bottom: 26px;
`;

const Description = styled.p`
  font-size: 1.13rem;
  font-weight: 300;
  color: #333333;
  text-align: center;
  line-height: 1.75rem;
  letter-spacing: -0.5px;
  margin-bottom: 60px;
`;

const Highlight = styled.span`
  color: ${({ theme }) => theme.orange};
  font-weight: 700;
`;
const FormBirthday = () => {
  const [showModal, setShowModal] = useState(false);
  const [birthday, setBirthday] = useState("");
  const [error, setError] = useState("");
  const [borderColor, setBorderColor] = useState("#D8D8D8");
  const navigate = useNavigate();
  const { state } = useLocation();

  const handleChange = (e) => {
    if (e.target.value.length > 6) {
      //   console.log("over");
      //   setError("생년월일 6자리를 입력해주세요.");
      return;
    }
    setBirthday(e.target.value);
  };

  const handleOnBlur = () => {
    setBorderColor("#D8D8D8");
  };

  const handleOnFocus = () => {
    setBorderColor("#111111");
    setError("");
  };

  const handleSubmitClick = () => {
    if (birthday.length === 6) {
      setShowModal(true);
    } else {
      setError("생년월일 6자리를 입력해주세요.");
      setBorderColor("#D60606");
    }
  };

  const handleOkClick = async () => {
    const response = await axios.patch(
      `${process.env.REACT_APP_API_SERVER_DOMAIN}/betterthan/participant`,
      {
        pid: state.pid,
        email: state.email,
        birthday,
      }
    );

    const date = response.data.participant.projectId.surveyDates[0].end;
    if (response.data.participant.projectId.feedbackType === FEEDBACK_TYPE.B) {
      navigate("/form/message", {
        state: {
          pid: response.data.participant.pid,
          name: response.data.participant.name,
          email: response.data.participant.email,
          projectName: response.data.participant.projectId.projectName,
          link: response.data.participant.surveyTinyUrl,
          date: `${new Date(date).getMonth() + 1}/${new Date(
            date
          ).getDate()}(${convertDay(date)})`,
        },
      });
      return;
    }

    navigate("/form/add", {
      state: {
        pid: response.data.participant.pid,
        name: response.data.participant.name,
        email: response.data.participant.email,
        domain: getEmailDomain(response.data.participant.email),
      },
    });
    setShowModal(false);
  };

  const handleCancelClick = () => {
    setShowModal(false);
  };
  return (
    <FormBox width={"600px"} height={"750px"}>
      <Heading>
        비밀번호로 사용할
        <br />
        생년월일을 입력해주세요
      </Heading>
      <Description>
        향후 리포트 확인 시 비밀번호로 활용할
        <br />
        <span style={{ fontWeight: 600 }}>생년월일 6자리</span>를 정확하게
        입력해주세요.
      </Description>
      <LabelInput
        type="number"
        label="생년월일"
        placeholder={"YYMMDD"}
        onBlur={handleOnBlur}
        onChange={handleChange}
        onFocus={handleOnFocus}
        borderColor={borderColor}
        value={birthday}
        setValue={setBirthday}
      />
      {error && <ErrorText msg={error} style={{ marginTop: "10px" }} />}
      <Spacer height={"61"} />
      <Button
        text="입력 완료"
        onClick={handleSubmitClick}
        disabled={birthday.length === 0}
      />
      {showModal && (
        <ModalBackground>
          <Modal
            width={"544px"}
            height={"388px"}
            closeHandler={handleCancelClick}
          >
            <BirthdayModal
              okHandler={handleOkClick}
              closeHandler={handleCancelClick}
              birthday={birthday}
            />
          </Modal>
        </ModalBackground>
      )}
    </FormBox>
  );
};

export default FormBirthday;
