import { useState } from "react";
import styled from "styled-components";
import ClearBtn from "./ClearBtn";

const Container = styled.div`
  width: 100%;
  border-radius: 0.94rem;
  border: 1px solid ${({ borderColor }) => borderColor};
  padding: 23px 34px;
  display: flex;
  flex-direction: column;
  position: relative;
`;

const Label = styled.label`
  color: #bbbbbb;
  font-size: 1rem;
  font-weight: 500;
  line-height: 1.19rem;
  letter-spacing: -1px;
  margin-bottom: 12px;
`;
const Input = styled.input`
  border: none;
  font-size: 1.38rem;
  color: #111111;
  line-height: 1.63rem;
  font-weight: 400;
  &:placeholder {
    color: #bbbbbb;
  }
  ::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
  ::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
`;

const LabelInput = ({
  type,
  placeholder,
  value,
  setValue,
  label,
  error,
  onChange,
  onKeyDown,
  borderColor,
  onFocus,
  onBlur,
}) => {
  return (
    <Container borderColor={borderColor}>
      <Label>{label}</Label>
      <Input
        type={type}
        placeholder={placeholder}
        value={value}
        onChange={onChange}
        onKeyDown={onKeyDown}
        onFocus={onFocus}
        onBlur={onBlur}
      />
      {value.length > 0 && (
        <ClearBtn
          style={{ right: "23px", bottom: "23px" }}
          onClick={() => setValue("")}
        />
      )}
    </Container>
  );
};

export default LabelInput;
