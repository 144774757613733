import axios from "axios";
import { useEffect } from "react";
import { useParams } from "react-router-dom";

const TinyUrl = () => {
  const { pid, uid } = useParams();
  console.log(pid, uid);
  useEffect(() => {
    (async () => {
      const response = await axios.post(
        `${process.env.REACT_APP_API_SERVER_DOMAIN}/betterthan/typeform`,
        { pid, uid }
      );
      if (response.data.ok) {
        window.location.href = response.data.redirectUrl;
      } else {
        alert(
          "링크가 유효하지 않습니다. 피드백 대상자에게 다시 한 번 확인 부탁드립니다."
        );
      }
    })();
  }, []);
  return <></>;
};

export default TinyUrl;
