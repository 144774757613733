import styled from "styled-components";
import ErrorText from "../ErrorText";
import MButton from "./MButton";
import MDescription from "./MDescription";
import MHeader from "./MHeader";

const Background = styled.div`
  position: fixed;
  z-index: 1;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  /* overflow: auto; Enable scroll if needed */
  background-color: rgb(17, 17, 17); /* Fallback color */
  background-color: rgba(17, 17, 17, 0.6); /* Black w/ opacity */
  display: flex;
  justify-content: center;
  align-items: flex-end;
  //   padding: 20px;
`;

const Container = styled.div`
  width: 100%;
  height: 80%;
  /* height: ${({ height }) => `${height}` || "100%"}; */
  overflow-y: auto;
  border-top-left-radius: 0.75rem;
  border-top-right-radius: 0.75rem;
  padding: 42px 30px 32px 30px;
  background: white;
  position: relative;
`;

const FriendList = styled.ul`
  margin: 24px 0;
`;

const FriendItem = styled.li`
  border: 1px solid #d8d8d8;
  border-radius: 0.63rem;
  margin-bottom: 10px;
  width: 100%;
  padding: 17px 20px;
  display: flex;
  flex-direction: column;
`;

const FriendItemColumn = styled.div`
  color: #111111;
  font-weight: 400;
  font-size: 0.88rem;
  &:nth-child(1) {
    color: ${({ theme }) => theme.orange};
    flex: 1;
    margin-right: 20px;
  }
  &:nth-child(2) {
    width: 100%;
  }
`;

const ButtonContainer = styled.div`
  display: flex;
  justify-content: space-between;
`;

const Warning = styled.span`
  color: ${({ theme }) => theme.orange};
  font-weight: 600;
`;
const MBottomView = ({
  friends,
  duplicatedFriends,
  cancelHandler,
  confirmHandler,
}) => {
  const cancelClick = () => {
    cancelHandler();
  };

  const confirmClick = () => {
    console.log("confirm clicked");
    confirmHandler();
  };
  return (
    <Background>
      <Container>
        <MHeader style={{ marginBottom: "16px", fontWeight: 600 }}>
          아래와 같이{" "}
          <span style={{ color: "#FF812C", fontWeight: 700 }}>
            {friends.length}
          </span>{" "}
          명에게
          <br />
          설문을 요청하시겠어요?
        </MHeader>
        <MDescription>
          이름과 이메일 주소를 한번 더 확인하시고,
          <br /> 설문 발송하기 버튼을 눌러주세요.
          <br />
          <Warning>(이후 수정 및 재발송 불가)</Warning>
        </MDescription>
        <FriendList>
          {friends.map((friend, index) => (
            <FriendItem key={index}>
              <div style={{ display: "flex" }}>
                <FriendItemColumn>{index + 1}</FriendItemColumn>
                {/* <FriendItemColumn>{friend.name}</FriendItemColumn> */}
                <FriendItemColumn>{friend.email}</FriendItemColumn>
              </div>
              {duplicatedFriends.includes(friend.email) && (
                <ErrorText
                  style={{ fontSize: "10px", marginTop: "0.25rem" }}
                  msg={
                    "최근 3번 이상 요청 받은 구성원입니다. 가능하다면 다른 구성원으로 입력해주세요."
                  }
                />
              )}
            </FriendItem>
          ))}
        </FriendList>
        <ButtonContainer>
          <MButton onClick={cancelClick} style={{ width: "48%" }}>
            수정하기
          </MButton>
          <MButton
            onClick={confirmClick}
            type="confirm"
            style={{ width: "48%" }}
          >
            설문 발송하기
          </MButton>
        </ButtonContainer>
      </Container>
    </Background>
  );
};

export default MBottomView;
