import axios from "axios";
import { useState } from "react";
import { useForm } from "react-hook-form";
import { useLocation, useNavigate } from "react-router-dom";
import styled from "styled-components";
import MButton from "../../components/mobile/MButton";
import MDescription from "../../components/mobile/MDescription";
import MErrorText from "../../components/mobile/MErrorText";
import MFormBox from "../../components/mobile/MFormBox";
import MHeader from "../../components/mobile/MHeader";
import MLabelInput from "../../components/mobile/MLabelInput";
import MModal from "../../components/mobile/MModal";
import MSubmitBtn from "../../components/mobile/MSubmitBtn";
import Spacer from "../../components/Spacer";
import { FEEDBACK_TYPE } from "../../constants";
import { convertDay, getEmailDomain } from "../../utils";

const ModalHeader = styled.h2`
  color: #111111;
  font-size: 1.25rem;
  font-weight: 600;
  margin-bottom: 11px;
  text-align: center;
  line-height: 1.75rem;
`;

const ModalText = styled.p`
  font-size: 0.94rem;
  color: ${({ theme }) => theme.orange};
  letter-spacing: -0.5px;
  margin-bottom: 22px;
  text-align: center;
`;

const ModalNumber = styled.p`
  text-align: center;
  font-size: 2.5rem;
  font-weight: 600;
  line-height: 3rem;
  border: 1px solid #d8d8d8;
  border-radius: 0.94rem;
  padding: 10px;
  margin-bottom: 26px;
`;

const MBirthday = () => {
  const { state } = useLocation();
  const {
    register,
    handleSubmit,
    watch,
    reset,
    formState: { errors },
  } = useForm({ mode: "onBlur" });
  const { onChange } = register("birthday");
  const navigate = useNavigate();

  const [showModal, setShowModal] = useState(false);

  const handleConfirmClick = async () => {
    // setShowModal(false);
    const birthday = watch("birthday");

    const response = await axios.patch(
      `${process.env.REACT_APP_API_SERVER_DOMAIN}/betterthan/participant`,
      {
        pid: state.pid,
        email: state.email,
        birthday,
      }
    );
    console.log(response.data.participant);
    const date = response.data.participant.projectId.surveyDates[0].end;
    if (response.data.participant.projectId.feedbackType === FEEDBACK_TYPE.B) {
      navigate("/form/message", {
        state: {
          pid: response.data.participant.pid,
          name: response.data.participant.name,
          email: response.data.participant.email,
          projectName: response.data.participant.projectId.projectName,
          link: response.data.participant.surveyTinyUrl,
          date: `${new Date(date).getMonth() + 1}/${new Date(
            date
          ).getDate()}(${convertDay(date)})`,
        },
      });
      return;
    }
    navigate("/form/add", {
      state: {
        pid: response.data.participant.pid,
        name: response.data.participant.name,
        email: response.data.participant.email,
        domain: getEmailDomain(response.data.participant.email),
      },
    });
    // navigate("/form/send");
  };

  const handleCancelClick = () => {
    setShowModal(false);
  };
  console.log(errors);
  const onSubmit = async (data) => {
    // navigate("add");

    setShowModal(true);
  };
  return (
    <MFormBox>
      <MHeader style={{ fontWeight: 400, marginBottom: "29px" }}>
        비밀번호로 사용할
        <br />
        생년월일을 입력해주세요
      </MHeader>
      <MDescription style={{ marginBottom: "37px" }}>
        향후 리포트 확인 시 비밀번호로 활용할
        <br />
        <span style={{ fontWeight: 600 }}>생년월일 6자리</span>를 정확하게
        입력해주세요.
      </MDescription>
      <form onSubmit={handleSubmit(onSubmit)}>
        <MLabelInput
          labelText="생년월일"
          label="birthday"
          type="number"
          inputMode="numeric"
          placeholder={"YYMMDD"}
          register={register}
          changeHandler={onChange}
          errors={errors}
          reset={() => reset()}
          watch={() => watch("birthday")}
          required={{
            required: true,
            maxLength: {
              value: 6,
              message: "6자 YYMMDD 형식으로 입력하세요",
            },
            minLength: {
              value: 6,
              message: "6자 YYMMDD 형식으로 입력하세요",
            },
          }}
        />
        {errors?.birthday?.message && (
          <MErrorText style={{ marginTop: "12px" }}>
            {errors?.birthday?.message}
          </MErrorText>
        )}
        <Spacer height={50} />
        <MSubmitBtn value="입력 완료" watch={() => watch("birthday")} />
      </form>
      {showModal && (
        <MModal>
          <ModalHeader>
            화면에 표시된 숫자가
            <br /> 내 생년월일이 맞나요?
          </ModalHeader>
          <ModalText>리포트 확인을 위해 꼭 기억해 주세요!</ModalText>
          <ModalNumber>{watch("birthday")}</ModalNumber>
          <MButton
            style={{ marginBottom: "10px" }}
            type="cancel"
            onClick={handleCancelClick}
          >
            다시 입력하기
          </MButton>
          <MButton type="confirm" onClick={handleConfirmClick}>
            확인 완료
          </MButton>
        </MModal>
      )}
    </MFormBox>
  );
};

export default MBirthday;
